.escm__features-container__feature {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-direction: row;

    margin: 1rem;
}

.escm__features-container__feature-title {
    flex: 1;
    max-width: 180px;
    margin-right: 2rem ;
}

.escm__features-container__feature-title h2 {
    font-family: var(--font-family);
    font-weight: 800;
    font-size: 18px;
    line-height: 24px;
    /* identical to box height, or 312% */

    letter-spacing: -0.04em;

    color: #FFFFFF;
}

.escm__features-container__feature-title div {
    
    width: 38px;
    height: 3px;
    
    background: var(--gradient-bar);
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.2);
    margin-bottom: 0.25rem;
}

.escm__features-container__feature-text {
    flex: 2;
    max-width: 390px;
    display: flex;
}

.escm__features-container__feature-text p {
    font-family: var(--font-family);
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    /* or 188% */


    color: var(--color-text);
}

@media screen and (max-width : 550px) {
    .escm__features-container__feature-title h{
        font-size: 14px;
        line-height: 22px;
    }
    .escm__features-container__feature-text p {
        font-size: 12px;
        line-height: 22px;
    }

    .escm__features-container__feature {
        margin: 1rem 0;
        flex-direction: column;
    }

    

}