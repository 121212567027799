.escm__blog{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;

    margin: 4rem
}

.escm__blog-heading {
    width: 100%;
    text-align: left;
    margin-bottom: 5rem;

}

.escm__blog-heading h2 {
    
    font-family: var(--font-family);
    font-weight: 800;
    font-size: 62px;
    line-height: 75px;
    /* or 121% */

    letter-spacing: -0.04em;
}

.escm__blog-container {
    display: flex;
    flex-direction: row;
    
}

.escm__blog-container__groupA {
    flex: 0.75;
    margin-right: 2rem;
}   

.escm__blog-container__groupB {
    flex: 1;
    grid-gap: 2rem;
    display: grid;
    grid-template-columns: repeat(2,1fr);
}

@media screen and (max-width:990px) {
    .escm__blog{
        padding: 0;
    }
    .escm__blog-container{
        flex-direction: column;
    }
    .escm__blog-container__groupA {
        
        margin: 2rem 0;
    }   
}

@media screen and (max-width: 650px) {
    .escm__blog {
        margin: 1rem;
    }

    .escm__blog-heading h2 {
        font-size: 48px;
        line-height: 65px;
    }
   .escm__blog-container__groupB {
        width: 100%;
        grid-gap: 1rem;
        display: grid;
        grid-template-columns: repeat(1,1fr);
    } 
    .escm__blog-container__groupA {
            
        margin: 1rem 0;
    }   
}


