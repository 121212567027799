.escm__cta {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin: 4rem;
    padding: 2rem;

    background: var(--gradient-bar);
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 10.7236px;
}

.escm__cta-content p {
    font-family: var(--font-family);
    font-weight: 500;
    font-size: 12px;
    line-height: 30px;
    /* or 250% */


    color: #0E0E0E;
}

.escm__cta-content h3 {
    font-family: var(--font-family);
    font-weight: 800;
    font-size: 24px;
    line-height: 45px;
    /* or 188% */


    color: #000000;
}

.escm__cta-button {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 2rem;
}

.escm__cta-button button{
    font-family: var(--font-family);
    font-weight: 700;
    font-size: 18px;
    line-height: 30px;
    /* identical to box height, or 167% */


    color: #FFFFFF;
    background: #000000;
    border-radius: 40px;
    padding: 0.5rem 1rem;
    border: none;
    outline: none;
    cursor: pointer;
    min-width: 150px;

}

@media screen and (max-width: 650px) {
    .escm__cta {
        flex-direction: column;
        
    }

    .escm__cta-button {
        margin-top: 1rem;
    }
}

@media screen and (max-width: 550px) {
    .escm__cta {
        margin: 2rem;
    }
}