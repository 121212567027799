.escm__header{
    display: flex;
}

.escm__header-content {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
    margin-right: 5rem;

}

.escm__header-content h1 {
    font-family: var(--font-family);
    font-weight: 800;
    font-size: 62px;
    line-height: 75px;
    letter-spacing: -0.04em;

}

.escm__header-content p {
    font-family: var(--font-family);
    font-weight: normal;
    font-size: 20px;
    line-height: 27px;
    color: var(--color-text);

    margin-top: 1.5rem;
}

.escm__header-content__input {
    width: 100%;
    margin: 2rem 0 1rem;

    display: flex;
}

.escm__header-content__input input {
    flex: 2;
    width: 100%;
    min-height: 50px;
    font-family: var(--font-family);
    background: var(--color-footer);
    border-radius: 5px 0px 0px 5px;
    font-size: 20px;
    font-weight: normal;
    line-height: 27px;
    border: 2px solid var(--color-footer);
    padding: 0 1rem;
    outline: none;
    color: #fff;
}

.escm__header-content__input button {
    flex: 0.6;
    width: 100%;
    min-height: 50px;
    padding: 0.5rem 1rem;
    color: #fff;
    background: #FF4820;
    font-family: var(--font-family);
    font-weight: 500;
    font-size: 20px;
    line-height: 27px;
    border-radius: 0 5px 5px 0;
    border: none;
    outline: none;
    cursor: pointer;
}

.escm__header-content__people {
    display: flex;
    width: 100%;
    justify-content: flex-start;
    align-items: center;
}

.escm__header-content__people img{
    width: 181.79px;
    height: 38px;
}

.escm__header-content__people p {
    margin: 0 0 0 1rem;
    font-family: var(--font-family);
    font-weight: 500;
    font-size: 12px;
    line-height: 38px;
    color: #fff;
    text-align: center;
}

.escm__header-image {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
}

.escm__header-image img {
    width: 100%;
    height: 100%;
}

@media screen and (max-width: 1050px) {
      .escm__header {
        flex-direction: column;
      }  

      .escm__header-content {
        margin: 0 0 3rem;
      }
}

@media screen and (max-width: 650px) {
    .escm__header h1 {
      font-size: 48px;
      line-height: 60px;
    }  

    .escm__header p {
        font-size: 16px;
        line-height: 24px;
      }  

    .escm__header-content__people {
        flex-direction: column;
    }

    .escm__header-content__people p {
       margin: 0;
    }
    .escm__header-content__input input,
    .escm__header-content__input button {
        font-size: 16px;
        line-height: 24px;
    }
}

@media screen and (max-width: 650px) {
    .escm__header h1 {
        font-size: 36px;
        line-height: 48px;

    }
    
    .escm__header p {
        font-size: 14px;
        line-height: 24px;

    }

    .escm__header-content__input input,
    .escm__header-content__input button {
        font-size: 12px;
        line-height: 16px;
    }
}

