.escm__article {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    background: var(--color-footer);
   

}

.escm__article-image{
    width: 100%;
    height: 100%;
   background: var(--color-footer);
    
}
.escm__article-image img{
    width: 100%;
    height: 100%;
}

.escm__article-content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    padding: 1rem 1.5rem;
    height: 100%;
}

.escm__article-content p {
    font-family: var(--font-family);
    font-weight: 700;
    font-size: 11.649px;
    line-height: 35px;
    /* identical to box height, or 300% */


    color: #FFFFFF;
}
.escm__article-content h3 {
    font-family: var(--font-family);
    font-weight: 800;
    font-size: 25.11px;
    line-height: 30px;
    /* or 121% */

    margin-bottom: 5rem;
    color: #FFFFFF;
}
.escm__article-content p:last-child {
    cursor: pointer; 
 
}

@media screen and (max-width: 550px) {
    .escm__article-content h3 {
        font-size: 20px;
    }
    
}