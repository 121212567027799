.escm__possibility {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-between;
}

.escm__possibility-image {
    flex: 1;
    display: flex;
    justify-content: flex-start;
    align-items:center;

    margin-right: 2rem;
}

.escm__possibility-image img {
    width: 100%;
    height: 100%;
}

.escm__possibility-content {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-start;

    margin-right: 2rem;
}

.escm__possibility-content h4 {
    font-family: var(--font-family);
    font-weight: 500;
    font-size: 16px;
    line-height: 30px;

    margin-left: 0;
    color: #71E5FF;
    cursor: pointer;
}

.escm__possibility-content h2 {
    font-family: var(--font-family);
    font-weight: 800;
    font-size: 34px;
    line-height: 45px;
    margin: 1rem 0  ;
}

.escm__possibility-content p {
    font-family: var(--font-family);
    font-weight: 500;
    font-size: 16px;
    line-height: 30px;

    /* identical to box height, or 188% */

    margin-top: 1rem;
    color: #FF8A71;

}

.escm__possibility-content__text p {
    font-family: var(--font-family);
    font-weight: 400;
    font-size: 16px;
    line-height: 30px;
    /* or 188% */


    color: var(--color-text);
}

@media screen and (max-width: 950px) {
    .escm__possibility {
        flex-direction: column;
    }

    .escm__possibility-image {
        margin: 1rem 0;
    }

    .escm__possibility-content {
        margin-top: 2rem;
    }
    
}